import React from "react";
import './HowItWorks.css';

const HowItWorks = (props) => {
  const { title, img, link, position } = props;

  function defPosition() {
    const pos = position;
    switch (pos) {
      case 'TopRight':
        return ({
          fontWeight: 600,
          fontSize: '7vw',
          marginLeft: '20%'
        })

      case 'Center':
        return ({
          fontSize: '6vw',
          fontWeight: 400,
        })

      case 'BottomRight':
        return ({
          fontSize: '6vw',
          fontWeight: 400,
          marginTop: '13%',
        })

      case 'TopCenter':
        return ({
          fontSize: '5vw',
          fontWeight: 400,
          marginBottom: '10%',
          width: '40%'
        })
      default:
        return ({
        })
    }
  }

  return (
    <div className="hiwContainer">
      <img src={img} alt="" />
      <div className="hiwTitle" style={defPosition()}>{title}</div>
    </div>
  );
}

export default HowItWorks;
