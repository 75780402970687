export let MobileMenu =  {
    en : {
        home: 'Home',
        login: 'Login',
        privacy : 'Privacy Policy',
        contact : 'Contact'
    },

    es : {
        home: 'Inicio',
        login: 'Iniciar Sesión',
        privacy : 'Politica de Privacidad',
        contact : 'Contacto'
    }
}