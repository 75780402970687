import { Header } from './Header'
import {Home} from './Home'
import {Footer} from './Footer'
import { MobileMenu } from './mobileMenu'


export let getCommonData = {
    en: {
        header: Header.en,

        home: Home.en,

        footer: Footer.en,

        mobileMenu: MobileMenu.en



    },
    es: {
        header: Header.es,

        home: Home.es,

        footer: Footer.es,

        mobileMenu: MobileMenu.es
    }

}