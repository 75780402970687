import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Inter/Inter-Black.ttf';
import './fonts/Inter/Inter-Bold.ttf';
import './fonts/Inter/Inter-ExtraBold.ttf';
import './fonts/Inter/Inter-ExtraLight.ttf';
import './fonts/Inter/Inter-Light.ttf';
import './fonts/Inter/Inter-Medium.ttf';
import './fonts/Inter/Inter-Regular.ttf';
import './fonts/Inter/Inter-SemiBold.ttf';
import './fonts/Inter/Inter-Thin.ttf';
import './fonts/candara/Candara_Bold_Italic.ttf';
import './fonts/candara/Candara.ttf';
import './fonts/candara/Candara_Italic.ttf';
import './fonts/candara/Candara_Bold.ttf';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
