import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/pagination'
import './mobileCarouselStyle.css'


const MobileCarousel = (props) => {
  const { items } = props;
  return (
    <div className='mobileCarouselContainer'>
      <Swiper
        modules={[Pagination]}
        loop={false}
        pagination={{ clickable: true }}
        spaceBetween={16}
        slidesPerView={1.15}


      >
        {items.map((item, i) => (
          <SwiperSlide className='mobileSlide' key={`step ${i}`}>

            <img style={{ height: "32.40vh", borderRadius: "30px", position: "absolute" }} src={item.imageUrl} alt="" />
            <div className='mobileOpacityLayer' />
            <div className="mobileCarrousel_title">{item.title}</div>

          </SwiperSlide>
        ))}
      </Swiper>

    </div>
  )
}

export default MobileCarousel;