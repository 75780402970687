import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import './header.css';
import { HashLink} from 'react-router-hash-link';

const Header = (props) => {
    const {onMenuClick, showMenu, changeLanguage, data } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("English")

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }
    const handleLanguageChange = (value) => {
        const selected = value.currentTarget.lang;
        setSelectedLanguage(value.currentTarget.innerText);
        changeLanguage(selected);
        setIsOpen(false);
    }

    return (
        <div className='headContainer'>
            <div className='headerLogo'>
                <Link to="/home">
                    <img src='Beyondlogo1.png' alt="Beyond" style={{ width: '100%', height: 'auto' }} />
                </Link>
            </div>
            <div className='headerItems'>
                <div className='langContainer'>
                    <button className='astext' onClick={toggleDropdown}>
                        {selectedLanguage}
                        <img src="Lang_White.png" alt="lang" />
                    </button>
                    {isOpen && (
                        <ul className="dropdown-options">
                            <li onClick={handleLanguageChange.bind(this)} lang="es">Español</li>
                            <li onClick={handleLanguageChange.bind(this)} lang="en">English</li>
                        </ul>
                    )}
                </div>
            </div>
            {/*mobile menu**/}
            <div className={`menu-icon ${showMenu ? "hide-icon" : ""}`} onClick={onMenuClick}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
        </div>
    );
}

export default Header;