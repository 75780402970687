import './home.css';
import Carousel from '../../components/carousel/carousel';
import MobileCarousel from '../../components/mobileCarousel/mobileCarousel';
import HowItWorks from '../../components/howItWorks/HowItWorks';
import HowItWorkMobile from '../../components/howItWorks/HowItWorkMobile';


const Home = (props) => {
    const { data } = props;

    return (

        <>

            <div className='mobileContent'>
                <MobileCarousel items={data.carousel} />
                <div className='homeTitle'>{data.titles.hiw}</div>
                <div className='homeTitle' style={{ width: '90%' }}>{data.subtitle[0]}</div>
                <div style={{ marginTop: '0.01%' }}></div>
                <div className='homeTitle'>{data.subtitle[1]}</div>
                <div style={{ marginTop: '5%' }}></div>
                <div className='homeVideo'>
                    <iframe className="player" src="https://www.youtube.com/embed/lStfMcNjzAg" allowFullScreen title='Beyond'></iframe>
                </div>
                <div className='howTitle'>{data.titles.hiw}</div>
                <div className='steps'>
                    {data.steps.content.map((stepM, index) => (
                        <div key={`step ${index}`}>
                            <HowItWorkMobile title={stepM.title} img={stepM.src} link={stepM.link} />
                        </div>
                    ))}
                </div>
                <div className='homeTitle' style={{ width: '90%' }}>{data.mobileCta}</div>
                <div className='homeButtonContainer'>
                    <a className='homeButton' href={data.waitingList}>{data.btnJoin}</a>

                </div>
            </div>


            <div className='homeContent'>
                <Carousel items={data.carousel} />
                <div className='homeTitle'>{data.titles.wb}</div>
                <div className='homeTitle'>{data.subtitle[0]}</div>
                <div style={{ marginTop: '0.2%' }}></div>
                <div className='homeTitle'>{data.subtitle[1]}</div>
                <div className='homeVideo'>
                    <iframe className="player" src={data.videoDemo} allowFullScreen title='Beyond'></iframe>
                </div>
                <div className='howTitle'>{data.titles.hiw}</div>
                <div className="steps">
                    {data.steps.content.map((stepM, index) => (
                        <div key={`step ${index}`}>
                            <HowItWorks title={stepM.title} position={stepM.position} img={stepM.src} link={stepM.link} />
                        </div>
                    ))}
                </div>
                <div className='homeTitle'>{data.cta}</div>
                <div className='homeButtonContainer'>
                    <a className='homeButton' href={data.waitingList}>{data.btnJoin}</a>
                </div>

            </div>
        </>
    );
}

export default Home;