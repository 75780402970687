export let Carousel =  {
    en: [
        { title: "MEMORIAM", imageUrl: "Memoriam.webp", text: "Looking for a way to honor and commemorate our loved ones and furry pets in a unique way? Welcome to Memoriam, the web3 space where you can come and remember it.",button: 'Pending Text'},
        { title: "LEGACY", imageUrl: "Legacy.webp", text: "Is a tool that can help you overcome the pain of losing a loved one. or wonderful pet. We know it's not easy to say goodbye and often there are things left unsaid.",button: 'Pending Text' },
        { title: "PASS AWAY", imageUrl: "Passaway.webp", text: "Can you imagine having a place where you can store your most important memories or those of your pet and have your digital twin with Legacy, it's possible.",button: 'Pending Text' },
    ],

    es: [
        { title: "MEMORIAM", imageUrl: "Memoriam.webp", text: "¿Buscas una forma de honrar y conmemorar a nuestros seres queridos y a nuestras mascotas de una manera única? Bienvenido a Memoriam, el espacio web3 donde puedes venir a recordarlo.", button: "Texto Pendiente" },
        { title: "LEGADO", imageUrl: "Legacy.webp", text: "Es una herramienta que puede ayudarle a superar el dolor de perder a un ser querido o a una mascota maravillosa. Sabemos que no es fácil decir adiós y que a menudo quedan cosas sin decir.", button: "Texto Pendiente" },
        { title: "PASS AWAY", imageUrl: "Passaway.webp", text: "Te imaginas tener un lugar donde almacenar tus recuerdos más importantes o los de tu mascota y tener a tu gemelo digital, con Legacy, es posible.", button: "Texto Pendiente" },
    ]
    
}