export let HIW = {
    en: {
        content: [
            {title: "Sign in", position: "TopRight", src: "Paso1.webp", link: ''},
            {title: "Create Avatar", position: "Center", src: "Paso2.webp", link: ''},
            {title: "Select Space", position: "BottomRight", src: "Paso3.webp", link: ''},
            {title: "Build Your Legacy", position: "TopCenter", src: "Paso4.webp", link: ''},
        ]
    },

    es: {
        content: [
            {title: "Registro", position: "TopRight", src: "Paso1.webp", link: ''},
            {title: "Crear Avatar", position: "Center", src: "Paso2.webp", link: ''},
            {title: "Seleccionar Espacio", position: "BottomRight", src: "Paso3.webp", link: ''},
            {title: "Construye tu legado", position: "TopCenter", src: "Paso4.webp", link: ''},
        ]
    }

}