import React from "react";
import './mobileMenu.css';
import { useState } from "react";

const MobileMenu = (props) => {
  const { isOpen, onClose, changeLanguage, data } = props;
  const [langOpen, setLangOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English")

  const toggleDropdown = () => {
    setLangOpen(!langOpen);
  }

  const handleLanguageChange = (value) => {
    const selected = value.currentTarget.lang;
    setSelectedLanguage(value.currentTarget.innerText);
    changeLanguage(selected);
    setLangOpen(false);
  }

  return (
    <nav className={`mobile-menu ${isOpen ? "active" : ""}`}>
      <ul>
        <li>
          <div className="mobileSelectLang" onClick={toggleDropdown}>
            {selectedLanguage}
            <img src="Lang_White.png" alt="lang" />
          </div>
          {langOpen && (
            <ul className="dropdown-options-mobile">
              <li onClick={handleLanguageChange.bind(this)} lang="es">Español</li>
              <li onClick={handleLanguageChange.bind(this)} lang="en">English</li>
            </ul>
          )}
          
          <hr/>
<div className="socialMediaMobile">

<p>Visit us in</p>
                <a href="https://www.facebook.com/profile.php?id=100092500538370&mibextid=LQQJ4d"><img src="facebook_logo.png" alt="" /></a>
                <a href="https://www.youtube.com/@GO-BYND-LIFE"><img src="youtube_logo.png" alt="" /></a>
                <a href="https://instagram.com/gobeyondmetaverse"><img src="instagram_logo.png" alt="" /></a>
            </div>
        </li>

      </ul>
    </nav>
  );
}

export default MobileMenu;
