// For more 
/* import { useRef, useEffect } from 'react';
 */
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import the swiper modules used in the carousel
import {Autoplay, Pagination } from 'swiper'

// Import the styles that the swiper components need to work properly
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/pagination'

//Impor the css for the component
import './carouselStyle.css'


const Carousel = (props) => {
    const {items} = props;
    return (
        <>
            <Swiper
                // Assign the modules and attributes of the carousel
                pagination={{
                    clickable: true, 
                }}
                loop={true}
                speed={500}
                slidesPerView={1}
                autoplay={{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                }}
                modules={[Autoplay, Pagination]}
                className='Hswiper'
            >
                {items.map((item,i) => (
                    <SwiperSlide className='swiperslide' key={`step ${i}`}>
                    <>
                        <div className='opacityLayer'/>
                        <div className="carouselDiv">
                            <h1 className="carrousel_title">{item.title}</h1>
                            <p className="carrousel_text">{item.text}</p>
                        </div>
                        <img src={item.imageUrl} alt="" />
                    </>
                </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}

export default Carousel;