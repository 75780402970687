import { Link } from "react-router-dom";
import './footer.css';

const Footer = (props) => {
    const { logo} = props;
    return (
        <div className="footerCont">
            <div><img src={logo} alt="" /></div>
            <div className="footerSocialMedia">
                <a href="https://www.facebook.com/profile.php?id=100092500538370&mibextid=LQQJ4d"><img src="facebook_logo.png" alt="" /></a>
                <a href="https://www.youtube.com/@GO-BYND-LIFE"><img src="youtube_logo.png" alt="" /></a>
                <a href="https://instagram.com/gobeyondmetaverse"><img src="instagram_logo.png" alt="" /></a>
            </div>
        </div>
    );


}

export default Footer;