export let Header =  {
    en: {
        lang: "English",
        hiw: "How it works",
        pricing: "Pricing",
        au: "About Us",
        login: "Login"
    },

    es:{
        lang: "Español",
        hiw: "Como Funciona",
        pricing: "Precios",
        au: "Sobre Nosotros",
        login: "Iniciar Sesión"
    }        
}