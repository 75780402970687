import React from "react";
import './HowItWorkMobile.css';

const HowItWorkMobile = (props) => {
  const { title, img, position} = props;
  function defPosition() {
    const pos = position;
    switch (pos) {
      case 'TopRight':
        return ({
          fontWeight: 600,
          fontSize: '7vw',
          marginLeft: '20%'
        })

      case 'Center':
        return ({
          fontSize: '6vw',
          fontWeight: 400,
        })

      case 'BottomRight':
        return ({
          fontSize: '6vw',
          fontWeight: 400,
          marginTop: '13%',
        })

      case 'TopCenter':
        return ({
          fontSize: '5vw',
          fontWeight: 400,
          marginBottom: '10%',
          width: '40%'
        })
      default:
        return ({
        })
    }
  }
  return (
    <div className="hiwMobileContainer">  
      <img className="hiwMobileImg" src={img} alt=""/>
      <div className="hiwMobileTitle">{title}</div>

    </div>
  );
}

export default HowItWorkMobile;
