import './App.css';
import Home from './pages/Home/home';
import Footer from './components/footer/footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { getCommonData } from './data/commonData';
import React, { useState } from 'react';
import Header from './components/header/header';
import MobileMenu from './components/mobileMenu/mobileMenu';
import { Helmet } from 'react-helmet';



function App() {
  const data = getCommonData;
  const [lang, setLang] = useState(data.en);
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const changeLanguage = (value) => {
    console.log("language changed:" + value);
    switch (value) {
      case "en":
        setLang(data.en);
        break;

      case "es":
        setLang(data.es);
        break;

      default:
        setLang(data.en);
        break;

    }
  }

  const closeMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="App">
      <Helmet>
        <meta property="og:title" content="Beyond After Life" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Beyond is the Metaverse where you leave your memories, honor those who are no longer here, say goodbye to those who have already left, even your pets." />  
        <meta property="og:image" content="https://www.gobyond.xyz/Paso1.webp" />
        <meta property="og:url" content="https://www.gobyond.xyz/" />

        <meta name="description" content="Beyond is the Metaverse where you leave your memories, honor those who are no longer here, say goodbye to those who have already left, even your pets." />
        <title>Beyond After Life</title>
        <link rel="icon" href="/favicon_BYND.png" />
        <meta name="keywords" lang='en' content="afterlife,metaverse,memories,legacy,pets,life,secrets" />
        <meta name="keywords" lang='es' content="metaverso,mascotas,recuerdos,duelo,legado,avatar,tracender,vida" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="Beyond" />

      </Helmet>
      <Router>
        <Header onMenuClick={closeMenu} showMenu={isMenuOpen} changeLanguage={changeLanguage} data={lang.header} />
        <Routes>
          <Route index element={home(lang.home, lang.footer, closeMenu, isMenuOpen, changeLanguage, lang.header)} />
          <Route path='*' element={home(lang.home, lang.footer, closeMenu, isMenuOpen, changeLanguage, lang.header)} />
        </Routes>
        <MobileMenu isOpen={isMenuOpen} onClose={closeMenu} changeLanguage={changeLanguage} data={lang.mobileMenu} />
      </Router>
    </div>
  );
}


const home = (langHome, langFooter) => {
  return (
    <>
      <Home data={langHome} />
      <div className='foot'><Footer logo='Beyond-afterlife-logo.png' items={langFooter} /></div>
    </>
  );
}


export default App;