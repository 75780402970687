export let Members =  {
    en: [
        { name: "Name of the employee", role: "Role of the employee", description: "Description of a beyond afterlifeEmployee" },
        { name: "Name of the employee", role: "Role of the employee", description: "Description of a beyond afterlifeEmployee" },
        { name: "Name of the employee", role: "Role of the employee", description: "Description of a beyond afterlifeEmployee" },
        'Know more'
    ],

    es: [
        { name: "Nombre del empleado", role: "Rol del Empleado", description: "Descripción del empleadonBeyon AfterLife" },
        { name: "Nombre del empleado", role: "Rol del Empleado", description: "Descripción del empleadonBeyon AfterLife" },
        { name: "Nombre del empleado", role: "Rol del Empleado", description: "Descripción del empleadonBeyon AfterLife" },
        'Saber Más'
    ]
}