export let Footer =  {
    en: [
        { id: 0, title: '', items: [{ url: '', sub: '' }, { url: '', sub: '' }, { url: '', sub: '' }] }
        
    ],

    es: [
        { id: 0, title: '', items: [{ url: '', sub: '' }, { url: '', sub: '' }, { url: '', sub: '' }] }
    ]

}