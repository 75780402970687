import { Carousel } from "./Carousel";
import { HIW } from "./HIW";
import { Members } from "./Members";

export let Home = {
    en: {
        carousel: Carousel.en,
        titles: {
            hiw: "HOW LEGACY WORKS",
            ot: "OUR TEAM",
            wb: "WELCOME TO BEYOND"
        },
        members: Members.en,

        subtitle: ['A Metaverse for your memory where you can discover the path to create a legacy for yourself and your loved ones, including your pets.','Join us to learn more'],


        cta: 'Do you want to leave a legacy to your loved ones of your memories and secrets?',

        mobileCta: 'Do you want to leave a legacy?',

        steps: HIW.en,

        waitingList: 'https://forms.gle/E6jMp1LX74Y9jC9S9',
        videoDemo: 'https://www.youtube.com/embed/86NT_sQMV2A?si=SwiZxrED7Aug8Gvt',
        btnJoin: 'Give it a Try'


    },

    es: {
        carousel: Carousel.es,
        titles: {
            hiw: "COMO FUNCIONA LEGADO",
            ot: "NUESTRO EQUIPO",
            wb: "BIENVENIDO A BEYOND"

        },
        members: Members.es,

        subtitle: ['Un Metaverso para tu memoria donde podrás descubrir el camino para crear un legado para ti y tus seres queridos, incluidas tus mascotas.','Únete a nosotros para saber más'],

        cta: '¿Quieres dejar un legado a sus seres queridos de sus recuerdos y secretos?',

        mobileCta: '¿Quieres dejar un legado?',
        
        steps: HIW.es,

        waitingList: 'https://forms.gle/CNAYTuMtFMpyKNkD9',
        videoDemo: 'https://www.youtube.com/embed/B7pz3aCVZ5A?si=4VIfwRHFtNquzhK_',
        btnJoin: 'Prueba Nuesta App'
    }

}